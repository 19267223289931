<i18n>
ru:
  deleteButton: Подтвердить
  deleteReason: Причина удаления
  error: Не удается удалить профиль
  header: Вы уверены?
  notLoggedIn: 'Чтобы удалить профиль, сначала войдите в Личный кабинет.'
  subheader: Восстановление данных будет невозможно
ua:
  deleteButton: Підтвердити
  deleteReason: Причина видалення
  error: Не вдається видалити профіль
  header: Ви впевнені?
  notLoggedIn: 'Щоб видалити профіль, спочатку увійдіть до Особистого кабінету'
  subheader: Відновлення даних буде неможливим
us:
  deleteButton: Approve
  deleteReason: Deletion reason
  error: 'Unable to delete your account, please contact administrator.'
  header: Are you sure you want to delete your account?
  notLoggedIn: Sign in if you want to delete your account
  subheader: It will be impossible to recover your personal data
</i18n>

<template>
  <div
    class="v-account-delete-title"
    :class="{ 'v-popup-head v-title': fromPopup }"
    v-html="translate('deleteAccountPopup.header')"
  />
  <div
    class="v-account-delete-subtitle"
    v-html="translate('deleteAccountPopup.subheader')"
  />

  <div class="v-d-flex v-w-100 v-flex-column v-mb-xs">
    <v-input
      class-name="v-arora-input"
      focus-on-mount
      required
      v-form-validator="{
        Form: validatorForm,
        Value: deleteReason,
        Required: true,
        Validations: ['length'],
        MaxLength: 255
      }"
      :label="translate('deleteAccountPopup.deleteReason')"
      v-model:text="deleteReason"
    />
    <arora-button
      class-name="btn-no v-flex-100 v-mb-sm"
      :label="translate('confirm.cancel')"
      @click="closePopup"
    />
    <arora-button
      class-name="btn-yes v-flex-100"
      :disabled="!deleteReason"
      :label="translate('deleteAccountPopup.deleteButton')"
      @click="deleteAccountProxy"
    />
  </div>
  <div
    v-if="accountDeleteError"
    class="v-account-delete-error v-text-center v-error-color"
    v-html="accountDeleteError"
  />
</template>

<script setup lang="ts">
import { ErrorCode } from '~api/consts'

import { useValidationStore, vFormValidator, VInput } from '@arora/common'
import { getActivePinia } from 'pinia'

const accountStore = useAccountStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
const { fromPopup } = useInstance()

const accountDeleteError = ref<string | null>(null)
const deleteReason = ref('')

const validatorForm = 'deleteReason'

async function closePopup(): Promise<void> {
  await popupStore.closePopup()
}

async function deleteAccountProxy(): Promise<void> {
  if (!deleteReason.value || !validationStore.formPassedCheck(validatorForm)) return

  if (appConfig.VueSettingsPreRun.AccountDeleteShowConfirm) {
    await popupStore.closePopup()

    await popupStore.showConfirm({
      message: translate('deleteAccountPopup.header'),
      type: 'warning',
      yesOrNo: true,
      yesFunction: () => {
        deleteAccount()
      }
    })
  } else {
    await deleteAccount()
  }
}

async function deleteAccount(): Promise<void> {
  const error = await accountStore.deleteAccount(deleteReason.value)

  handleErrorMessage(error.code)
}

function handleErrorMessage(code: number): void {
  if (code === 0) {
    navigateTo(appConfig.VueSettingsPreRun.Links.MainLink)

    return
  }

  switch (code) {
    case ErrorCode.NotLoggedIn:
      accountDeleteError.value = translate('deleteAccountPopup.notLoggedIn')
      break
    default:
      accountDeleteError.value = translate('deleteAccountPopup.error')
      break
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-account-delete-title {
  font-weight: 700;
  font-size: variables.$TextSizeH3;
  margin-bottom: 10px;
}

.v-account-delete-subtitle {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 25px;
}

.v-account-delete-error {
  margin: 15px auto 0 auto;
}
</style>
